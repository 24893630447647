import './App.css';
import React, { useEffect } from 'react';

function App() {
  useEffect(() => {
    document.title = 'Tal3a'; // Set your desired title here
  }, []);
  return (
    <div className="page-container">
    {/* Background Image */}
    <div className="background-image"></div>
      {/* Logo on the top left */}
      <div className='logo'>
        <img src="/logo-delete.png" alt="Logo"/> <p>al3a</p>
      </div>
    {/* Content Container */}
    <div className="content-container">

      <h1 className="title">Coming soon</h1>
      {/* Paragraph */}
      <div className='qbox'>
        <p className="paragraph">
        <strong>Who are we</strong><br></br>Tal3a is a platform that will renovate outings in Jordan! 
        </p>
        <p className="paragraph">
        <strong>Objective</strong><br></br>Answer the most repeated question by Jordanians "What can we do for fun in Jordan?"
        </p>
      </div>
    </div>
      <p className="contact-us">
      <strong>Contact us:</strong> <a href="mailto:info@tal3aactivities.com">Info@tal3aactivities.com</a>
      </p>
  </div>
  );
}

export default App;
